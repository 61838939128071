let config = {
    apiUrl: 'https://luckey.tongren119.com/v1', //物联?
    // apiUrltest:'http://192.168.0.163:8882',
    // apiUrltest:'http://192.168.0.172:8882/v1',
    apiUrltest:'https://luckey.tongren119.com/v1',

};

if (process.env.NODE_ENV === 'development') { //本地
    config.host = config.apiUrltest;
} else if (process.env.NODE_ENV === 'production') { //线上
    config.host = config.apiUrl;
}


export default config