import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import Print from 'vue-print-nb'

import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/common.less'// custom define global less
import {get,post} from './utills/http'
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.use(ElementUI);
Vue.use(Print)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
