import axios from 'axios';
import {Message } from 'element-ui';
// import db from '@/utils/localstorage.js'
import router from '@/router'
import config from './config.js'
import store from '../store'
axios.defaults.timeout = 20000;
//axios.defaults.baseURL = config.apiUrl;
if (process.env.NODE_ENV === 'development') { //本地
    axios.defaults.baseURL =config.apiUrltest;
} else if (process.env.NODE_ENV === 'production') { //线上
    axios.defaults.baseURL = config.apiUrl;
}

//启动关闭loading，配置loading
// let loading=null;
// function startLoading() {
//     console.log(Loading)
//   loading = Loading.service({
//     lock: true,
//     text: "Loading...",
//     target: document.querySelector('.loading-area')//设置加载动画区域
//   });
// }
// function endLoading() {
//     console.log(loading)
//   loading.close();
// }
// 计数请求条数，如果当前请求条数为0则关闭loading
// let needLoadingRequestCount = 0;
// function showFullScreenLoading() {
   
//     if (needLoadingRequestCount === 0) {
//         console.log("准备加载loading")
//         startLoading();
//     }
//     needLoadingRequestCount++;
// };
// function tryHideFullScreenLoading() {
//     if (needLoadingRequestCount <= 0) return;
//     needLoadingRequestCount--;
//     if (needLoadingRequestCount === 0) {
//       endLoading();
//     }
// };



//http request 拦截器
axios.interceptors.request.use(
    http => {
        // showFullScreenLoading();
     
      
        // const token = db.get('token') || null;
        http.data = JSON.stringify(http.data);
       

            http.headers = {
                'content-type': 'application/json'
            };
        
        if(router.app._route.path==='/login'){
            return http;
        }
        if(http.url==='terminal/fireAlarmControl'||http.url==='terminal/sendTerminalMessage' || http.url.indexOf('terminal')!=-1){
        //    console.log(http)
            http.baseURL = 'https://www.qutgroup.com/fcserver/'
        }
      
      
        // if(token){
        //     if(config.noToken.indexOf(http.url)==-1){
        //         http.headers.token= token
        //     }
        // }else{
        //     router.replace({
        //         path: '/login',
        //     });
        // }
        return http;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    response => {
        // tryHideFullScreenLoading();

      

        if (response.status === 200) {
            if(response.data.code > 200){
                Message.error(response.data.msg)
                switch (response.data.code) {
                    // 1005: 未登录
                    case 401:
                        store.commit('clearUser');
                        setTimeout(() => {
                            router.replace({
                                path: '/login'
                            });
                        }, 1000);
                        break;
                    
                    // 清除本地token和清空vuex中token对象
                    // 跳转登录页面
                    // case '1004':
                    //     store.commit('clearUser');
                    //     // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                    //     setTimeout(() => {
                    //         router.replace({
                    //             path: '/login'
                    //         });
                    //     }, 1000);
                    //     break;
                }
            }else{
                return Promise.resolve(response);
            }

        } else {
            return Promise.reject(response);
        }


    },
    error => {

        // Message.error('请求失败')
        return Promise.reject(error);
    }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params,_timmout={timeout:60000}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            _timmout
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params = {},_timmout={timeout:60000}) {
    return new Promise((resolve, reject) => {
        axios.post(url,params,_timmout)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
}
export function formPost(url, params = {}) {
    return new Promise((resolve, reject) => {
       axios({
           url,
           method:'post',
           data:params
       }).then(res=>{
           resolve(res.data)
       }).catch(err=>reject(err))
       ;
    })
}