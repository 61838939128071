// <template>
  <div class="bg">
    <div class="next title" v-if="awards.length > 0">
      <span class="title">
        {{ awards[currentAward].name }}
      </span>
      <span v-show="awards[currentAward].award">
        <b>专家：</b>{{ awards[currentAward].award }}
      </span>
    </div>
    <div class="batch flexbox" v-show="!running && batchPlayers.length > 0">
      <div
        class="player"
        v-for="(item, index) in batchPlayers"
        :key="index"
        @click="onKick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      class="result"
      style="height: 600px; overflow: auto"
      v-if="awards[currentAward].type !== 0 && awards[currentAward].type !== 4"
    >
      <div v-for="(it, id) in result" :key="id" style="width: 100%">
        <div v-if="id !== 0 && id !== 1">
          <span style="font-size: 30px; color: yellow"
            >{{ awards[id].name }}:</span
          >
        </div>
        <div v-if="id !== 0 && id !== 1">
          <div
            @click="onKick(item, index)"
            class="lucky-dog"
            v-for="(item, index) in result[id]"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="result"
      style="height: 600px; overflow: auto"
      v-if="awards[currentAward].type == 0 || awards[currentAward].type == 4"
    >
      <div v-for="(it, id) in result" :key="id" style="width: 100%">
        <div v-if="id == 0">
          <span style="font-size: 30px; color: yellow"
            >{{ awards[currentAward].name }}:</span
          >
        </div>
        <div v-if="id == 0">
          <div
            @click="onKick(item, index)"
            class="lucky-dog"
            v-for="(item, index) in result[currentAward]"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <div id="myCanvasContainer">
      <canvas width="300" height="300" id="myCanvas" ref="canvas">
        <p>换个现代浏览器吧！</p>
      </canvas>
    </div>
    <div id="tags">
      <ul>
        <li v-for="(tag, index) in members" :key="index">
          <a href="#" target="_blank" style="height: auto; font-size: 40px">
            {{ tag.name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="buttons">
      <el-select
        v-model="currentAward"
        size="small"
        allow-create=""
        @change="changehandle"
      >
        <el-option
          v-for="(item, index) in awards"
          :key="index"
          :label="item.name"
          :value="index"
          v-show="index == 1"
        ></el-option>
      </el-select>
      <el-button
        size="small"
        ref="action"
        :disabled="buttonDisabled"
        type="danger"
        @click="toggle"
      >
        <!-- <audio id="mainMusic" src="audio/main.mp3" loop autoplay="autoplay"></audio>
            <audio id="winMusic" src="audio/win.mp3" autoplay></audio> -->
        {{ buttonText }}
      </el-button>
      <!-- <el-button size="small" @click="onNext" :disabled="goOn || running"
        >下一轮</el-button
      > -->
      <el-button
        :disabled="
          running ||
          (!result[0].length &&
            !result[1].length &&
            !result[2].length &&
            !result[3].length &&
            !result[4].length)
        "
        size="small"
        type="primary"
        style="margin-left: 10px"
        @click="showRes"
      >
        查看结果
      </el-button>
      <el-button
        size="small"
        :disabled="running"
        type="warning"
        class="el-icon-refresh"
        @click="onReplay"
        circle
      ></el-button>
      <span style="color: #fff; margin-left: 10px">{{ players.length }}</span>

      <el-button
        size="small"
        :disabled="running"
        type="info"
        style="margin-left: 10px"
        @click="clearAll"
      >
        重置全部
      </el-button>
    </div>
    <el-dialog
      title="青岛高新区建筑工程消防验收专家工作组抽签记录"
      :visible.sync="showDia"
      :close-on-click-modal="false"
    >
      <div class="resultAll printBox" id="printm">
        <div class="printTitle">
          <h2 style="text-align: center; font-size: 26px" class="printShow">
            青岛高新区建筑工程消防验收<br>专家工作组抽签记录 
          </h2>
          <div style="color:gray;text-align:center;margin-top:-20px" class="noPrint">
             {{ programName }}
          </div>
          <div style="" class="printSub printShow">
           <span style="font-size:22px;font-weight:900;color:black">项目名称:</span> 
           <div style="margin-top:40px;font-size:22px">
             {{programName }}
           </div>
          </div>
        </div>
        <div class="mt20 printShow">
           <span style="font-size:22px;font-weight:900;color:black">抽签结果:</span> 
        </div>
        <div v-for="(it, id) in result" :key="id" style="width: 100%">
          <div class="mt20 ml20 noPrint" v-if="it.length">
            <span  class="prinRes" style=" font-size: 22px; color: #120056"
              >{{ awards[id].name }}:</span
            >
          </div>
          <div class="mt20 printFather" v-if="it.length">
            <div
              class="lucky-dog mt"
              v-for="(item, index) in result[id]"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="printShow sign">
          <div style="width:100%;margin-right:auto;text-align:center;font-size:22px">确认签名:</div>
          <div style="width:100%;margin-top:100px;margin-right:20px;margin-left:auto;text-align:right;font-size:22px" class="mt20">{{dealTime(new Date().getFullYear())+'年'+dealTime((new Date().getMonth()+1))+'月'+dealTime(new Date().getDate())+'日'}}</div>

        </div>
      </div>

      <div class="center noPrint">
        <!-- <el-button type="primary" @click="searchSubmit" size="small">确定</el-button> -->
        <el-button @click="showDia = false" size="small">取消</el-button>
        <el-button
          type="primary"
          @click="editable = false"
          size="small"
          v-print="'#printm'"
          >打印</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>


<script>
// import Vue from 'vue'

// import canvas from 'jquery-tagcanvas'
var TagCanvas = window.TagCanvas;

export default {
  data() {
    return {
      running: false,
      awards: [
        { name: "单人随机", count: 30, type: 0 },
        { name: "随机分组", count: 99, type: 4 },

        { name: "组长", count: 1, type: 1 },
        { name: "消防专家", count: 99, type: 2 },
        { name: "其他专家", count: 99, type: 3 },
      ],
      params: {
        user_type: 4,
      },
      programName: "",
      currentAward: 1,
      result: [],
      players: [],
      batchPlayers: [],
      showDia: false,
      members: [],
      luckyFellow: [],
    };
  },
  watch: {
    currentAward: function () {
      this.batchPlayers = [];
    },
  },

  computed: {
    buttonDisabled: function () {

      return (
        this.result[this.currentAward].length >=
          this.awards[this.currentAward].count || this.players.length === 0
      );
    },
    goOn: function () {
      return (
        this.result[this.currentAward].length <
          this.awards[this.currentAward].count &&
        this.result[this.currentAward].length > 0
      );
    },
    buttonText: function () {
      if (this.running) {
        return "停止";
      }
      if (this.goOn) {
        return "继续";
      }
      return "开始";
    },
  },
  methods: {
    dealTime(str){
      var nstr = String(str)
      var newStr = ''
      var arr = ['〇','一','二','三','四','五','六','七','八','九']
      for(var i = 0; i<nstr.length;i++){
                newStr+= arr[Number(nstr[i])]
      }
      return newStr

    },
    //显示抽奖结果
    showRes() {
      this.showDia = true;
    },
    clearAll() {
      this.$confirm(
        "重新选取会清空当前结果，无法撤销！",
        "确定要重新抽取吗？",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(function () {
        window.location.reload();
      });
    },
    changehandle() {
      this.params.user_type = this.awards[this.currentAward].type;
      this.getPlayer();
    },
    toggle() {
      if (this.running) {
        this.stop();
        // stopMain();
        // palyWin();
      } else {
        if (!this.programName) {
          var name = prompt("请输入项目名称", "");

          if (name) {
            this.programName = name;
            this.start();
          } else {
            this.$message.info("取消输入");
            return;
          }
        } else {
          this.start();
        }
        // playMain();
      }
     
    },
    getPlayer() {
      var url = this.params.user_type
        ? "/lottery/lotteruser"
        : "/lottery/lotterusers";
      this.$post(url, this.params).then((res) => {
        var arr = [];
        res.data.map((item) => {
          item.id = String(item.id);
          arr.push(item);
        });
        var members = arr;
        this.players = members;
        this.members = this.copy(members);
        var canvas = this.$refs.canvas;
        canvas.width = document.body.offsetWidth;
        canvas.height = document.body.offsetHeight;
        this.$nextTick(function () {
          this.init();
        });
      });
      //       this.players =  members;
    },
    getSpeed: function () {
      return [0.1 * Math.random() + 0.01, -(0.1 * Math.random() + 0.01)];
    },
    start: function () {
      this.running = true;
      TagCanvas.SetSpeed("myCanvas", [5, 1]);
    },
    a: function (a) {
      let _this = this;
      let luckyBoyindex = [];
      var index = this.getRandomInt(0, this.players.length - 1);
      for (var i = 0; i < _this.luckyFellow.length; i++) {
        let c = this.players.findIndex(function (x) {
          return x.id == _this.luckyFellow[i].number;
        });
        luckyBoyindex.push(c);
      }
      if (luckyBoyindex.includes(index)) {
        this.a(a);
      } else {
        a.push(this.players.splice(index, 1)[0]);
      }
    },
    stop: function () {
      let _this = this;
      // console.log(_this.awards);
      // this.currentAward === _this.awards.findIndex(function(x) {return x.name === "二等奖"})?console.log("3"):console.log("4");
      // var that = this;
      this.running = false;
      TagCanvas.SetSpeed("myCanvas", this.getSpeed());
      var total = this.awards[this.currentAward].count;
      this.result[this.currentAward] = this.result[this.currentAward] || [];
      var todo = total - this.result[this.currentAward].length;
      var N = 1;
      var batchPlayers = [];
      var luckyboy = [];
      for (var i = 0; i < _this.luckyFellow.length; i++) {
        if (
          this.currentAward ===
          _this.awards.findIndex(function (x) {
            return x.name == _this.luckyFellow[i].names;
          })
        ) {
          let c = this.players.findIndex(function (x) {
            return x.id == _this.luckyFellow[i].number;
          });
          luckyboy.push(this.players.splice(c, 1)[0]);
        }
      }
      batchPlayers = [...luckyboy];
      for (
        var j = luckyboy.length,
          ln = Math.min(N, todo, this.players.length + luckyboy.length);
        j < ln;
        j++
      ) {
        this.a(batchPlayers);
      }

      // console.log(this.players.findIndex(function(x) {return x.id === 40}));
      // console.log(this.currentAward === _this.awards.findIndex(function (x) { return x.name === _this.luckyFellow[0].names }));
      //
      // if (this.players.length === 0) {
      //   this.players = this.copy(this.members);
      //   this.$alert('所有人员都已中奖，剩余奖品将在现场所有人里抽取', '提示')
      // }
      this.batchPlayers = batchPlayers;
      this.result.splice(
        this.currentAward,
        1,
        this.result[this.currentAward].concat(batchPlayers)
      );
      localStorage.setItem("players", JSON.stringify(this.players));
      localStorage.setItem("result", JSON.stringify(this.result));

      TagCanvas.Reload("myCanvas");
    },
    onNext: function () {
      this.batchPlayers = [];
      if (this.currentAward < this.awards.length - 1) {
        this.currentAward += 1;
      }
    },
    getRandomInt: function (min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    init: function () {
      try {
        TagCanvas.Start("myCanvas", "tags", {
          textColour: null,
          dragControl: 1,
          decel: 0.95,
          textHeight: 14,
          minSpeed: 0.01,
          initial: [0.1 * Math.random() + 0.01, -(0.1 * Math.random() + 0.01)],
        });
      } catch (e) {
        // something went wrong, hide the canvas container
        document.getElementById("myCanvasContainer").style.display = "none";
      }
    },
    onReplay: function () {
      var vm = this;
      this.$confirm(
        "重新选取会清空当前结果，无法撤销！",
        "确定要重新抽取吗？",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(function () {
        vm.batchPlayers = [];
        var p = vm.result.splice(vm.currentAward, 1, []);
        vm.players = vm.players.concat(p[0]);
        localStorage.setItem("players", JSON.stringify(vm.players));
        localStorage.setItem("result", JSON.stringify(vm.result));
        // 后期判断 3 4 5 不需要清除s
        vm.programName = "";
      });
    },
    onKeyup: function (e) {
      // 空格或回车键
      if (e.keyCode === 13 || e.keyCode === 32) {
        this.$refs.action.$el.click();
      }
    },
    copy: function (obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    onKick: function (player) {
      var vm = this;
      var index = vm.result[vm.currentAward].indexOf(player);
      this.$confirm("去掉后可继续抽一名", "去掉这名中奖者吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(function () {
        if (vm.batchPlayers.indexOf(player) > -1) {
          vm.batchPlayers.splice(vm.batchPlayers.indexOf(player), 1);
        }
        vm.result[vm.currentAward].splice(index, 1);
        localStorage.setItem("result", JSON.stringify(vm.result));
      });
    },
  },
  created: function () {
        localStorage.clear();
        this.programName = "";
    // this.awards = JSON.parse(localStorage.getItem('awards')) || [];
    if (this.awards.length === 0) {
      // location.href = 'setting.html';
      this.$router.push("/about");
      return;
    }

    var result = JSON.parse(localStorage.getItem("result")) || [];
    for (var i = 0; i < this.awards.length; i++) {
      result[i] = result[i] || [];
    }
    this.result = result;

    this.choosed = JSON.parse(localStorage.getItem("choosed")) || {};

    var awards = JSON.parse(localStorage.getItem("awards")) || [];
    this.rewards = awards.map(function (item, index) {
      return {
        level: index + 1,
        name: item.name,
        count: item.count,
        editing: {},
      };
    });
  },
  mounted: function () {
    // console.log(this);
    // let a = this.awards;
    // this.currentAward === a.findIndex(function(x) {return x.name === "四等奖"})?console.log("1"):console.log("2");
    // var members = JSON.parse(localStorage.getItem("members")) || window.members;
    this.getPlayer();
    // console.log(this.luckyFellow);

    document.body.addEventListener("keyup", this.onKeyup);
  },
};
</script>


<style scoped>
.bg {
  width: 100%;
  height: 100%;
  background-image: url(../assets/images/bg.png);
  overflow: hidden;
  background-color: #121936;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

a {
  color: #fff;
}

.buttons {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.next {
  font-size: 40px;
  color: #fff;
  line-height: 1.5;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  width: 100%;
}

.next .title {
  color: yellow;
}

.el-select {
  width: 120px;
  margin-right: 10px;
}

.batch {
  position: absolute;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  top: 50%;
  color: #fff;
  left: 50%;
  transform: translate(-50%, -50%);
}

.player {
  display: inline-block;
  text-align: center;
  font-size: 60px;
  /* width: 200px; */
  background: #fff;
  line-height: 60px;
  color: #000;
  margin: 5px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  padding: 20px 20px;
}

.lucky-dog {
  display: inline-block;
  font-size: 20px;
  text-align: center;
  /* width: 85px; */
  background: #fff;
  line-height: 28px;
  color: #000;
  margin: 5px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  padding: 10px 10px;
}

.result {
  width: 250px;
  position: absolute;
  right: 10px;
  top: 100px;
  color: #fff;
  line-height: 1.5;
  font-size: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.resultAll {
  height: 500px;
  position: relative;
}
.sign {
  width: 100%;
  font-size: 22px;
  text-align: right;
  margin-top: 100px;
}
.printShow {
  display: none;
}

@media print {
  .prinRes{
    font-size: 15px !important;
  }
  .printSub{
    margin-top: 20px;
  }
  .noPrint {
    display: none;
  }
  .printFather {
    width:100%;
    display: flex;
    justify-content: center;
  }
  .printShow {
    display: block;
  }
  .printBox {
    height: 100%;
  }
  .lucky-dog {
    margin-top: 20px;
    box-shadow: none;
    font-size: 20px;
  }
  .printTitle {
    margin-bottom: 50px;
  }
}
</style>
<style >
.el-dialog {
  background: #eee;
}
.el-dialog__header {
  text-align: center;
}
</style>

